/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import { Meta } from "@atoms";

const Helmet = loadable(() =>
  import("react-helmet").then(({ Helmet: c }) => c)
);
const Footer = loadable(() => import("@organisms/Footer"));
const PageContainer = ({ children, className, path, lang, meta }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useAppState();
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      />
      <motion.main
        key={path}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: "tween",
          ease: "easeOut",
          duration: 0.5,
        }}
        onAnimationComplete={a => {
          if (a.entry) {
            if (typeof window !== "undefined") {
              const dataLayer = window.dataLayer || [];
              dataLayer.push({ event: "routeChange" });
            }
            dispatch({
              type: "endNavigation",
            });
            dispatch({
              type: "endTransition",
            });
          }
        }}
        className={classNames(
          className,
          "flex flex-grow flex-col min-h-screen"
        )}
      >
        {children}
      </motion.main>
      <Meta {...meta} />
      <Footer />
    </>
  );
};

PageContainer.defaultProps = {
  className: "",
  lang: "en",
};

export default PageContainer;
